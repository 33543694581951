import React, { useMemo } from "react";
import { Platform, StyleSheet } from "react-native";
import _ from 'lodash';
import { alignments } from "../../scales/alignments";
import PropTypes from 'prop-types';
import { AutoSizeText, ResizeTextMode } from "react-native-auto-size-text";
import constants from "./constants";
import { isInSequence } from "../../scales/utils";
const {SHOW_MARKS} = constants
const TrackMarkNumbers=(props) => useMemo(() => {
    const {indicationConfig: {indicationMultiple, indicationNumbers}, index, orientation,
    showMarks, step, min, max, isPercentage, requiredFontForMarks, numberOfTerms, value,
    scaleProps: {scaleWidth},
} = props
    const length = (Number(max)-Number(min))/Number(step)
    const displayNumber = `${index}${isPercentage?'%':''}`
    const maxNumber = `${max}${isPercentage?'%':''}`
    const containsDigit = () => {
        const arr = indicationNumbers.split(',')
        const digitStr = String(index);
        return arr.some(numStr => _.isEqual(numStr, digitStr));
    }
    const showNumber=()=> {
        return ((_.isNumber(indicationMultiple) && (index % indicationMultiple === 0)) || (!_.isEmpty(indicationNumbers) && containsDigit()) || (!_.isNumber(indicationMultiple) && _.isEmpty(indicationNumbers) && (index % 5 === 0)))
    }
    //is the same index already shown by trackmark component then we are not showing mark, only showing number
    const isMarkThere = () =>{
        if(value === index){
            return true;
        }
        switch(showMarks){
            case SHOW_MARKS.SHOW_MARKS: return isInSequence(index, min, max, step)
            case SHOW_MARKS.SHOW_EDGE_MARKS: return (index=== min || index===max) 
            case SHOW_MARKS.NO_MARKS: return index === value
        }
    }
    const getMarginLeft = () => {
        if(orientation === alignments.HORIZONTAL){
            return getMarginLeftBasedOnPlatform()
        }else{
            return (isMarkThere() ? 40 : 23)
        }
    }
    const getMarginTop = () => {
        if(orientation === alignments.HORIZONTAL){
            return isMarkThere() ? 35 : 20
        }else if(orientation === alignments.VERTICAL){
            if(length>=99 && (_.isNumber(indicationMultiple) && indicationMultiple < 4)){
                return -2
            }else{
                return -5
            }
        }
    }

    const getMarginLeftBasedOnPlatform = () => {
        if(length>100 ||numberOfTerms>100){
            return  0
        }else if(SHOW_MARKS.SHOW_EDGE_MARKS === showMarks && isPercentage){
            return Platform.OS === 'web' ? -25 : -13
        }else if(SHOW_MARKS.NO_MARKS === showMarks){
            return Platform.OS === 'web' ? -25 : -12
        }else if(Platform.OS === 'web' && isPercentage){
            return -8
        }else if(Platform.OS === 'web' && length>20){
            return -7
        } else{
            return -4
        }
    }

    const getStyle = () => {
        if(orientation === alignments.HORIZONTAL){
            return {
                ...styles.hzTextStyle,
                marginTop: getMarginTop(),
                 marginLeft: getMarginLeft(),
                 width: scaleWidth/numberOfTerms,
                 transform: [{ scale: (2/String(maxNumber).length) }],
            }
        } else if (orientation === alignments.MOBILE_VERTICAL) {
            const displayNumberLength = String(displayNumber).length
            return {
                color: '#000000',
                transform: [{ rotate: '90deg' }],
                marginBottom: -10,
                marginLeft: -(requiredFontForMarks*(displayNumberLength*0.3)),
                marginTop: 10
            }
        } else{
            const displayNumberLength = String(displayNumber).length

            return {
                ...styles.vtTextStyle,
                marginLeft: getMarginLeft(),
                marginTop: getMarginTopForVertical(displayNumberLength)
            }
    }
}


const getMarginTopForVertical = (displayNumberLength) => {
    if(((length>=99 && numberOfTerms>21) && displayNumberLength < 4)){
        return -2
    }else if((displayNumberLength >=4 && max > 100 && length > 20)){
        return 0
    }else{
        return -4
    }
}
    if(showNumber()){
            return (
                <AutoSizeText
                numberOfLines={1}
                fontSize={requiredFontForMarks}
                mode={ResizeTextMode.min_font_size}
                style={getStyle()}
                minFontSize = {1}
                >
                {displayNumber}
                </AutoSizeText>
        )
    }
    return <></>
}, [props.index, props.id])
const styles = StyleSheet.create({
    hzTextStyle: {
        position: 'absolute',
        color: '#000000'
    },
    vtTextStyle: {
       position: 'absolute',
        color: '#000000',
        marginRight: 5,
    },
  });
TrackMarkNumbers.defaultProps = {
    indicationConfig: {
        indicationMultiple: null,
        indicationNumbers: null
    },
    index: 0.1,
    showMarks: SHOW_MARKS.NO_MARKS,
    orientation: alignments.HORIZONTAL,
    step: 1,
    min: 0,
    max: 10,
    isPercentage: false,
    id: null,
    scaleProps: {}
  }
TrackMarkNumbers.propTypes = {
    isPercentage: PropTypes.bool,
    indicationConfig: PropTypes.shape({
        indicationMultiple: PropTypes.number,
        indicationNumbers: PropTypes.string,
    }),
    showMarks: PropTypes.string,
    orientation: PropTypes.string,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    index: PropTypes.number,
    id: PropTypes.string,
    scaleProps: PropTypes.shape({
        scaleWidth: PropTypes.number,
    }),
  }
export default TrackMarkNumbers