import { createForm } from 'rc-form'
import { Platform } from 'react-native'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActivityIndicator, Text, TouchableOpacity, View, ScrollView } from 'react-native'
import { buttonBackgroundColor } from '../../containers/NavigationScreens'
import api from '../../utils/api'
import showToast from '../../utils/toast'
import TextInput from './Input'
import { storage } from '../../utils/storage'
import { setSubjectAuthToken } from '../../utils/secureStorageUtils'
import constants from '../../constants/constants'
import openapi from '../../utils/openapi'
import { openMeeting } from '../../actions/login'
import { updatePasswordExpiry, logout, updateResetPassword } from '../../actions/users'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getUser } from '../../selectors/commonSelector'
import { decryptString } from '../../utils/cryptoUtil'

const passwordArr = [
  {
    label: 'PSWD_MIN_LENGTH',
  },
  {
    label: 'PSWD_ONE_NUMBER',
  },
  {
    label: 'PSWD_ONE_SC',
  },
  {
    label: 'PSWD_ONE_UPPER',
  },
  {
    label: 'PSWD_NO_SPACES',
  },
  {
    label: 'PSWD_RESTRICT',
  },
  {
    label: 'PSWD_NO_USERNAME',
  },
  {
    label: 'PSWD_NO_EMAILNAME',
  },
  {
    label: 'PSWD_NO_SITENAME',
  },
  {
    label: 'PSWD_NO_ORGNAME',
  },
]

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  changeLoading = () => {
    const { loading } = this.state
    this.setState({
      loading: !loading,
    })
  }

  compareToFirstPassword = (rule, value, callback) => {
    const {
      form,
      screenProps: { t },
    } = this.props
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('PSWD_NOT_MATCH'))
    }
    callback()
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && form.getFieldValue('confirmPassword')) {
      form.validateFields(['confirmPassword'])
    }
    callback()
  }

  validateMinLength = (rule, value, callback) => {
    const {
      form,
      screenProps: { t },
      currentUser,
    } = this.props
    const passwordCheckRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*(.)\1{4})(?!.*(abcde|bcdef|cdefg|defgh|efghi|fghij|ghijk|hijkl|ijklm|jklmn|klmno|lmnop|mnopq|nopqr|opqrs|pqrst|qrstu|rstuv|stuvw|tuvwx|uvwxy|vwxyz|01234|12345|23456|34567|45678|56789|43210|54321|65432|76543|87654|98765)+)(?=.*[`~!@#$%^&*()_\-+={}[\];:"',.\/<>?\\|])[A-Za-z\d\S]{8,64}$/
    const validPassword = passwordCheckRegex.test(form.getFieldValue('newPassword'))
    const email = currentUser.email
    const clientName = currentUser.studySite?.client?.name.split(' ').join('')
    const organizationCode = currentUser.studySite?.client?.primaryOrganizationCode
    if (
      value &&
      (form.getFieldValue('newPassword').length < 8 ||
        !validPassword ||
        _.includes(value.toLowerCase(), organizationCode.toLowerCase()) ||
        _.includes(value.toLowerCase(), clientName.toLowerCase()) ||
        _.includes(value.toLowerCase(), currentUser.firstName.toLowerCase()) ||
        _.includes(value.toLowerCase(), currentUser.lastName.toLowerCase()) ||
        (email && _.includes(value.toLowerCase(), email.split('@')[0].toLowerCase())) ||
        (email && _.includes(value.toLowerCase(), email.toLowerCase())))
    ) {
      callback(t('PSWD_VALIDATION'))
    }
    callback()
  }

  resetPassword = () => {
    const { form, navigation } = this.props
    const data = storage.getItem('credentials')
    const phoneNumber = navigation.getParam('phoneNumber') || data.phoneNo
    const values = form.getFieldsValue()
    if (
      values.newPassword &&
      values.newPassword.length > 7 &&
      values.newPassword === values.confirmPassword
    ) {
      const subject = {
        password: values.confirmPassword,
        phoneNo: phoneNumber,
      }
      this.submitNewPassword(subject)
    } else {
      form.validateFields()
      // form.getFieldError ('newPassword').map (errorInfo => (
      //   <Text
      //     key={errorInfo}
      //     style={{fontSize: 14, color: '#1976d2', paddingTop: 10}}
      //   >
      //     {errorInfo}
      //   </Text>
      // ))
    }
  }

  submitNewPassword = async (subject) => {
    const {
      navigation,
      screenProps: { t },
      logout: signoutUser,
      currentUser,
      appType,
      updateResetPassword,
      updatePasswordExpiry,
    } = this.props
    this.changeLoading()
    const accessCode = navigation.state.params?.accessCode
    const fromLogin = navigation.getParam('fromLogin')
    const subjectDeviceToken = decryptString(storage.getString(currentUser.id+'SubjectAuthorizationToken'))
    const passwordExpired =
      navigation.getParam('passwordExpired') != null
        ? navigation.getParam('passwordExpired')
        : false

    try {
      if (!fromLogin) {
        const res = await openapi.post('/subject/resetPassword', subject)
        showToast(t('PswdResetSuccess'), 'success', 2000)
        signoutUser()
        navigation.goBack()
      } else if (currentUser && (currentUser.resetPassword || passwordExpired) && fromLogin) {
        const res = await api.post(
          `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${currentUser.subjectId}/changePassword`,
          subject
        )
        showToast(t('PswdResetSuccess'), 'success', 2000)
        if(passwordExpired) {
          updatePasswordExpiry(false)
        }
        updateResetPassword(false)
        const newSubjectDeviceToken = res.headers['subjectauthorization-token']
        if (Platform.OS === 'web' && subjectDeviceToken !== newSubjectDeviceToken) {
          setSubjectAuthToken(
            res.headers['subjectauthorization-token'],
            res.headers['subjectrefresh-token'],
            currentUser.id
          )
        }
        if (accessCode && constants.AppType.SITESTAFF !== appType) {
          openMeeting(accessCode)
        } else {
          if (currentUser && currentUser.role === 'CAREGIVER') {
            navigation.navigate('SubjectDataLoaderPage')
          } else {
            navigation.navigate('OfflineDataLoader')
          }
        }
      }
    } catch (error) {
      this.setState({
        loading: false,
      })
      if (error.response) {
        if (error.response.status === 500) {
          showToast(t('FailedResetPWD'), 'danger', 3000)
        } else {
          console.log(error)
        }
      } else {
        showToast(t('NetworkError'), 'danger', 3000)
        console.log(error)
      }
    }
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldError },
      navigation,
      screenProps: { t },
      onMobile
    } = this.props
    const { loading } = this.state
    return (
      <View
        // keyboardVerticalOffset={Header.HEIGHT + 10}
        // style={styles.container}
        // behavior="padding"
        // enabled={true}
        style={{ flex: 1, backgroundColor: '#ffffff' }}
      >
        <ScrollView style={{ backgroundColor: '#ffffff' }} showsVerticalScrollIndicator={false}>
          <View
            style={{
              flex: 1,
              margin: 40,
              width: onMobile ? '90%' : '50%',
              alignSelf: 'center',
            }}
          >
            {loading && (
              <View
                style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
              >
                <ActivityIndicator
                  size="large"
                  color={buttonBackgroundColor}
                  overlayColor="rgba(0, 0, 0, 0.07)"
                />
              </View>
            )}
            {/* <Card style={{ borderRadius: 10 }}>
          <CardItem style={{ borderRadius: 10 }}>
            <Body style={{ alignItems: 'center', borderRadius: 10 }}> */}
            {/* Forgot password? */}
            {/* <View style={{ paddingVertical: 10 }}>
          <Text style={{ fontSize: 25, color: '#455a64', fontFamily: 'Inter' }}>
            {t('ResetPassword')}
          </Text>
        </View> */}
            <View style={{ paddingTop: 10, width: '100%' }}>
              <Text style={{ color: '#90a4ae', fontFamily: 'Inter' }}>{t('NewPassword')}</Text>
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    required: true,
                    message: t('PswdEmpty'),
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                  {
                    validator: this.validateMinLength,
                  },
                ],
              })(
                <TextInput
                  textInputProps={{
                    autoFocus: true,
                    secureTextEntry: true,
                  }}
                  style = {{ 
                    borderBottomWidth: 1,
                    borderColor: '#cccccc',
                    width: '100%',
                  }}
                  //   placeholder={placeholder}
                  //   placeholderTextColor={placeholderTextColor}
                />
              )}
              {getFieldError('newPassword') !== undefined ? (
                getFieldError('newPassword').map((errorInfo) => (
                  <Text
                    key={errorInfo}
                    style={{
                      fontSize: 14,
                      color: '#f54248',
                      paddingTop: 10,
                      fontFamily: 'Inter',
                    }}
                  >
                    {errorInfo}
                  </Text>
                ))
              ) : (
                <Text />
              )}
            </View>
            <View style={{ paddingTop: 10, width: '100%' }}>
              <Text style={{ color: '#90a4ae', fontFamily: 'Inter' }}>{t('ConfirmPassword')}</Text>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: t('CnfPswdEmpty'),
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(
                <TextInput
                  textInputProps={{ secureTextEntry: true }}
                  style = {{ 
                    borderBottomWidth: 1,
                    borderColor: '#cccccc',
                    width: '100%',
                  }}
                  //   placeholder={placeholder}
                  //   placeholderTextColor={placeholderTextColor}
                />
              )}
              {getFieldError('confirmPassword') !== undefined ? (
                getFieldError('confirmPassword').map((errorInfo) => (
                  <Text
                    key={errorInfo}
                    style={{
                      fontSize: 14,
                      color: '#f54248',
                      paddingTop: 10,
                      fontFamily: 'Inter',
                    }}
                  >
                    {errorInfo}
                  </Text>
                ))
              ) : (
                <Text />
              )}
            </View>
            <View style={{ paddingTop: 0 }}>
              <TouchableOpacity
                onPress={this.resetPassword}
                style={{
                  alignSelf: 'center',
                  alignItems: 'center',
                  padding: 10,
                  backgroundColor: '#9155FD',
                  marginTop: 50,
                  width: 100,
                  // marginLeft: 120,
                  borderRadius: 5,
                }}
              >
                <Text style={{ color: '#fff', fontFamily: 'Inter', textTransform: 'uppercase' }}>
                  {t('Save')}
                </Text>
              </TouchableOpacity>
            </View>
            {/* Send OTP */}
            {/* Verify OTP */}
            {/* </Body>
          </CardItem>
        </Card> */}
            <View style={{ paddingTop: 20, width: onMobile ? '100%' : '110%' }}>
              <Text
                style={{
                  color: '#90a4ae',
                  fontFamily: 'Inter',
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                {t('PSWD_NOTE')}
              </Text>
              {passwordArr.map((data) => (
                <Text style={{ color: '#90a4ae', fontFamily: 'Inter' }}>{t(data.label)}</Text>
              ))}
            </View>
          </View>
        </ScrollView>
      </View>
    )
  }
}

ResetPassword.defaultProps = {
  onMobile: false,
}

ResetPassword.propTypes = {
  form: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  screenProps: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  onMobile: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  appType: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  updatePasswordExpiry: PropTypes.func.isRequired,
  updateResetPassword: PropTypes.func.isRequired,
}


const mapStateToProps = (state) => {
  return {
    currentUser: getUser(state),
    appType: state.appStatus?.appType,
    onMobile: state.appStatus?.onMobile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      updateResetPassword,
      updatePasswordExpiry,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(createForm()(ResetPassword))
