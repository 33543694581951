import React, { Component } from 'react'
import { connect } from 'react-redux'
import { View, Platform, Text, Dimensions } from 'react-native'
import { bindActionCreators } from 'redux'
import Diary from '../components/Diary'
import moment from 'moment'
import VisitLabel from '../components/visitLabel'
import appConstants from '../constants/constants'
import { getSvfsOfCurrentVisitWithMinEntries } from '../selectors/subjectVisitForm'
import { getCurrentVisit } from '../selectors/visits'
import api from '../utils/api'
import _ from 'lodash'
import { getTodayCallSchedule } from '../utils/meetingScheduleUtils'
import { Spinner } from 'native-base'
import { getOfflineFormsToSync, getSyncingStatus } from '../selectors/syncQueue'
import { NavigationEvents } from 'react-navigation'
import { fontResizer } from '../components/styles/fontResizer'
import { DATE_TIME_FORMAT, DATE_FORMAT, convertUtcToSubjectTimezone } from '../utils/dateUtils'
import constants from '../constants/constants'
import CustomTextResize from '../components/common/CustomTextResize'
import { setCurrentScreen, updateSelectedCall, updateSelectedDate, updateSelectedFormIdAndSelectedSvfId } from '../actions/session'
import { storeSyncStatus } from '../actions/syncQueue'
import { getQuestionnairesLoader } from '../selectors/studyMetaData'
import { getUserSession, getUserTimezone, getSubject } from '../selectors/commonSelector'
import PropTypes from 'prop-types'

class DiaryScreen extends Component {
  state = {
    callSchedules: [],
    loading: false,
  }

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    headerTitle: (
      <View style={{ left: 20 }}>
        <View style={{height: 40}}>
          <CustomTextResize
              textStyle={{
                paddingTop: Platform.OS === 'web' ? 0 : 10,
                color: '#212121',
                fontFamily: 'Inter',
                fontWeight: '400',
                paddingBottom: Platform.OS === 'web' ? 15 : 10,
              }}
              fontSize={fontResizer(24)}
              title={navigation?.state?.params?.title
                ? t('HomePatnt_Diary') + ' (' + navigation.state.params.title + ')'
                : t('HomePatnt_Diary')}
              viewStyles={{justifyContent: 'flex-start'}}
          />
        </View>
        <View>
          {navigation.state.params && navigation.state.params.subTitle && (
            <View
              style={{ flexDirection: Dimensions.get('window').width >= 410 ? 'row' : 'column' }}
            >
              <Text
                style={{
                  color: '#212121',
                  fontSize: fontResizer(14),
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  paddingBottom: Platform.OS === 'web' ? 0 : 10,
                }}
                numberOfLines={1}
              >
                {`${t('VisitWindow')} : `}
              </Text>
              <Text
                style={{
                  color: '#212121',
                  fontSize: fontResizer(14),
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  paddingTop: Dimensions.get('window').width >= 390 ? 0 : 5,
                  paddingBottom: Platform.OS === 'web' ? 0 : 10,
                }}
                numberOfLines={1}
              >
                {navigation.state.params.subTitle}
              </Text>
            </View>
          )}
        </View>
      </View>
    ),
  })

  componentDidUpdate(prevProps) {
    const { navigation, scheduleDate } = this.props
    const isTraining = navigation.getParam('isTraining')

    if (prevProps.scheduleDate !== scheduleDate) {
      if (!isTraining) {
        this.retrieveCallSchedules()
      }
      this.refreshDiary()
    }

    const prevNavigation = prevProps.navigation
    const prevTraining = prevNavigation.getParam('isTraining')

    if (prevTraining !== isTraining) {
      this.refreshDiary()
    }
  }

  refreshDiary = () => {
    const { isDeviceOnline, setCurrentScreen, navigation, appType } = this.props

    const isTraining =
    appType === appConstants.AppType.SITESTAFF ? false : navigation.getParam('isTraining')

    const shouldRetrieveCallSchedules =
      !isTraining && isDeviceOnline && appType !== appConstants.AppType.SITESTAFF

    setCurrentScreen('DIARY_PAGE')

    if (shouldRetrieveCallSchedules) {
      this.retrieveCallSchedules()
    }
  }


  changeScheduleDate = (date) => {
    const { updateSelectedDate } = this.props
      updateSelectedDate(moment(date, 'DD-MMM-YYYY').format(DATE_FORMAT))
    
  }

  sortSvfsAndCallsByTime = (svfs) => {
    if (svfs.length > 0) return _.sortBy(svfs, ['scTimeSort'])
    return svfs
  }

  retrieveCallSchedules = async () => {
    const {
      subject: { id: subjectId },
      scheduleDate,
      timeZone
    } = this.props
    const selectedDate = scheduleDate
    try {
      this.setState({
        loading: true,
      })
      const res = await api.get(
        `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/calls?subjectId=${subjectId}&date=${selectedDate}&timezone=${timeZone}`
      )
      const callSchedules = res.data
      this.setState({
        callSchedules: callSchedules || [],
      })

      this.setState({
        loading: false,
      })
    } catch (error) {
      console.log(error)
      this.setState({
        loading: false,
      })
    }
  }
  getCurrentVisitStatus = () => {
    const { currentVisit, screenProps: { t }, scheduleDate } = this.props

    const visitStartDate = convertUtcToSubjectTimezone(currentVisit.visitStartDate, DATE_TIME_FORMAT)
    const visitEndDate = convertUtcToSubjectTimezone(currentVisit.visitEndDate, DATE_TIME_FORMAT)

    let visitLabelTitle = null
      
    if (!currentVisit.isEnabled) {
      visitLabelTitle = t('VisitNotStarted')
    } else if (moment(scheduleDate).isSame(moment(visitStartDate), 'day')) {
      visitLabelTitle = null
    } else if (moment(scheduleDate).isAfter(moment(visitEndDate), 'day')) {
      visitLabelTitle = t('VisitWindowClosed')
    } else if (moment(scheduleDate).isBefore(moment(visitStartDate), 'day')) {
      visitLabelTitle = t('VisitNotStarted')
    }
    return visitLabelTitle
  }

  render() {
    const {
      syncCompleted,
      navigation,
      storeSyncStatus,
      screenProps,
      isDeviceOnline,
      offlineFormsToSync,
      subject: { id },
      updateSelectedCall,
      isTrainingCompleted,
      formListLoading,
      updateSelectedFormIdAndSelectedSvfId,
      svfsOfCurrentVisit,
      scheduleDate,
      currentVisit,
      isSyncing,
      appType,
      timeZone
    } = this.props

    const { callSchedules, loading: callLoading } = this.state
    const isTraining = navigation.getParam('isTraining')
    let svfs = []

    let loCallSchedules = []
    if (!isTraining && appType !== appConstants.AppType.SITESTAFF) {
      loCallSchedules = getTodayCallSchedule(callSchedules, timeZone)
    }
    if (formListLoading) {
      return <Spinner color="#4e89ae" />
    }

    svfs = svfsOfCurrentVisit
    svfs = this.sortSvfsAndCallsByTime(svfs)
    svfs = _.orderBy(svfs, 'scheduleStartTime')
    loCallSchedules = this.sortSvfsAndCallsByTime(loCallSchedules)
    const visitStatus = this.getCurrentVisitStatus()
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {!isTraining && visitStatus && (
          <VisitLabel
            screenProps={screenProps}
            title={visitStatus}
            testID={`visitdiary-visitstatuslabel-${currentVisit?.id}-view`}
          />
        )}
        <NavigationEvents onDidFocus={() => updateSelectedFormIdAndSelectedSvfId(null, null)}/>
        <Diary
          syncCompleted={syncCompleted}
          isSyncing={isSyncing}
          storeSyncStatus={storeSyncStatus}
          OfflineFormsToSync={offlineFormsToSync}
          screenProps={screenProps}
          navigation={navigation}
          svfs={svfs}
          callData={loCallSchedules}
          changeScheduleDate={this.changeScheduleDate}
          refreshDiary={this.refreshDiary}
          loading={false}
          isDeviceOnline={isDeviceOnline}
          selectVisitForm={updateSelectedFormIdAndSelectedSvfId}
          callLoading={callLoading}
          timeZone={timeZone}
          subjectId={id}
          updateSelectedCall={updateSelectedCall}
          isTraining={isTraining}
          isTrainingCompleted={isTrainingCompleted}
          scheduleDate={scheduleDate}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  subject: getSubject(state),
  isDeviceOnline: state.appStatus.isDeviceOnline,
  isSyncing: getSyncingStatus(state),
  currentVisit: getCurrentVisit(state),
  svfsOfCurrentVisit: getSvfsOfCurrentVisitWithMinEntries(state),
  isTrainingCompleted: 
  state.appStatus?.appType === appConstants.AppType.SITESTAFF
      ? true
      : getSubject(state)?.trainingCompleted,
  formListLoading: getQuestionnairesLoader(state),
  offlineFormsToSync: getOfflineFormsToSync(state),
  scheduleDate: getUserSession(state)?.selectedDate,
  appType: state.appStatus?.appType,
  timeZone: getUserTimezone(state)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeSyncStatus,
      updateSelectedCall,
      updateSelectedFormIdAndSelectedSvfId,
      updateSelectedDate,
      setCurrentScreen,
    },
    dispatch
  )


  DiaryScreen.defaultProps = {
    subject : {
      id: null
    },
    updateSelectedFormIdAndSelectedSvfId: ()=> null,
    timeZone: null,
  }
  
  DiaryScreen.propTypes = {
   subject: PropTypes.shape({
    id: PropTypes.string,
   }),
   updateSelectedFormIdAndSelectedSvfId: PropTypes.func,
   timeZone: PropTypes.string
  } 
export default connect(mapStateToProps, mapDispatchToProps)(DiaryScreen)
