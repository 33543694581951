import React from 'react'
import { View } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { TouchableOpacity, Platform } from 'react-native'
import CustomDateInput from './webDatePicker/CustomDateInput'
import moment from 'moment'
import PropTypes from 'prop-types'
import constants from '../constants/constants'
import { getTimezoneCode } from '../utils/util'
import { DATE_FORMAT } from '../utils/dateUtils'

const {dateFormats} = constants

const DatePickerLayout = (props) => {
  const { onDateChange, initialDiaryDate, t, testID, locale, subjectTimezone } = props
  const getPreviousDate = () => {
    const previousDate = moment.utc(initialDiaryDate).subtract(1, 'days')
    onDateChange(previousDate)
  }

  const getNextDate = () => {
    const nextDate = moment.utc(initialDiaryDate).add(1, 'days')
    onDateChange(nextDate)
  }

  const getDateValue = () => {
      return moment(initialDiaryDate, DATE_FORMAT).format(dateFormats.DATE)
    }
  return (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: 'center',
        backgroundColor: '#CFD8DC',
        borderColor: '#CFD8DC',
        borderRadius: 5,
        paddingBottom: Platform.OS === 'web' ? 5 : 4,
        paddingLeft: 10,
        paddingRight: 10,
        fontFamily: 'Inter',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View>
        <TouchableOpacity
          onPress={() => getPreviousDate()}
          testID={`datePicker-previous-click-btn`}
          accessible={true}
        >
          <AntDesign name="doubleleft" size={14} color="#424242" />
        </TouchableOpacity>
      </View>
      <View>
          <CustomDateInput
            onChange={(date) => {
                onDateChange(date)
            }}
            date={getDateValue()}
            testID={testID}
            t={t}
            locale={locale}
            showTimeInput={false}
            valueFormat={dateFormats.DATE}
            mode="date"
            setFormat={dateFormats.DATE}
            isDiaryDatePicker={true}
            timezone = {subjectTimezone}
            shortTimezone={getTimezoneCode(subjectTimezone)}
          />
      </View>
      <View>
        <TouchableOpacity
          onPress={() => getNextDate()}
          testID={`datepicker-next-click`}
          accessible={true}
        >
          <AntDesign name="doubleright" size={14} color="#424242" />
        </TouchableOpacity>
      </View>
    </View>
  )
}

DatePickerLayout.defaultProps = {
  initialDiaryDate: moment().utc().format(),
  t: (key) => key,
  testID: 'datePicker',
  onDateChange: () => {},
  subjectTimezone: moment.tz.guess(),
}

DatePickerLayout.propTypes = {
  onDateChange: PropTypes.func,
  initialDiaryDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  t: PropTypes.func,
  testID: PropTypes.string,
  locale: PropTypes.string.isRequired,
  subjectTimezone: PropTypes.string,
}

export default DatePickerLayout
