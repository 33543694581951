export const Colors = {
    placeholderTextColor: '#bdbdbd',
    headingColor: '#212121',
    borderColor: '#cccccc',
    red: 'red',
    primaryTextColor: '#030303',
    primaryColor: '#9155FD',
    white: '#FFFFFF',
    backgroundColor: '#cfd8dc',
    blue: 'blue',
    darkGrey: '#424242',
    sliver: '#A6A6A6'

}