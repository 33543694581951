import React, { useMemo } from "react";
import {StyleSheet, View } from "react-native";
import _ from 'lodash'
import { alignments } from "../../scales/alignments";
import PropTypes from 'prop-types'
import constants from "./constants";
import { isInSequence } from "../../scales/utils";
const {SHOW_MARKS, SCALE_MARK_COLOR, SCALE_MARK_WIDTH, SCALE_SELECTED_MARK_COLOR,  SCALE_MAX_MARK_HEIGHT} = constants
const TrackMarkComponent = (props) => useMemo(() => {
    const {value, index, min, max, showMarks, step, orientation} = props
    const getShowMarkStyle = () => {
        const markColor = value === index ? SCALE_SELECTED_MARK_COLOR : SCALE_MARK_COLOR
        if(orientation === alignments.HORIZONTAL){
            return {...styles.hzMarkStyle,  borderRightColor: markColor, 
            }
        } else if (orientation === alignments.MOBILE_VERTICAL){
          return {...styles.mobileVtMarkStyle,  borderRightColor: markColor}
        } else {
            return {...styles.vtMarkStyle,  borderTopColor: markColor, marginTop: 0, marginLeft: -SCALE_MAX_MARK_HEIGHT/2}
        }
    }
    let markStyle = {}
    if(value === index || (_.isEqual(showMarks, SHOW_MARKS.SHOW_MARKS) && isInSequence(index, min, max, step)) || (
      (_.isEqual(showMarks, SHOW_MARKS.SHOW_EDGE_MARKS) && (index=== min || index===max))
    )){
      markStyle = getShowMarkStyle()
    }
    
  return (<View style={markStyle}></View>)
}, [props.value, props.index])

const styles = StyleSheet.create({
      hzMarkStyle: {
        width: SCALE_MARK_WIDTH,
        height: 30,
        borderRightWidth: SCALE_MARK_WIDTH,
        borderRightColor: SCALE_MARK_COLOR,
    },
    mobileVtMarkStyle: {
      width: SCALE_MARK_WIDTH,
      height: SCALE_MAX_MARK_HEIGHT,
      borderRightWidth: SCALE_MARK_WIDTH,
      borderRightColor: SCALE_MARK_COLOR,
      alignSelf: 'center',
  },
    vtMarkStyle: {
        width: SCALE_MAX_MARK_HEIGHT,
        height: SCALE_MARK_WIDTH,
        borderTopWidth: SCALE_MARK_WIDTH,
        borderTopColor: SCALE_MARK_COLOR,
    }
  });
  TrackMarkComponent.defaultProps = {
    step: 1,
    min: 0,
    max: 10,
    index: 0.1,
    value: null,
    showMarks: SHOW_MARKS.NO_MARKS,
    orientation: alignments.HORIZONTAL,
  }
  TrackMarkComponent.propTypes = {
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.string,
    showMarks: PropTypes.string,
    orientation: PropTypes.string,
    index: PropTypes.number,
  }
export default TrackMarkComponent