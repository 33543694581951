import { Feather, FontAwesome, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Container, Content, List, ListItem } from 'native-base'
import React, { Component } from 'react'
import { BackHandler, Platform, Text, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { retrieveHealthkitData } from '../actions/healthkit'
import { updateDeviceToken } from '../actions/login'
import { buttonBackgroundColor } from '../containers/NavigationScreens'
import api from '../utils/api'
import { Authorize } from '../utils/appPrivileges'
import CustomModal from '../utils/customModal'
import { deviceConfig } from '../utils/deviceConfig'
import { putItem, removeCredentials } from '../utils/secureStorageUtils'
import styles from './sideBar/styles'
import constants from '../constants/constants'
import _ from 'lodash'
import { clearStorageAndStoreSyncQueue } from '../actions/syncQueue'
import { clearAndStopBackGroundJob } from '../utils/backgroundTask/downloadImageUtil'
import { getUserStudySite } from '../selectors/studyMetaData'
import { getSubject, getUser } from '../selectors/commonSelector'
import PropTypes from 'prop-types'

class Sidebar extends Component {
  state = {
    isModalVisible: false,
  }

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    title: navigation.state.params ? navigation.state.params.title : t('Actn_sheetMore'),
  })

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', () => false)
  }

  logoutAction = async () => {
    const {
      navigation,
      screenProps: { t, setLocale },
      clientID,
      currentUser,
      clearStorageAndStoreSyncQueue,
      downloadImageTimerId,
      deviceToken,
      isDeviceOnline
    } = this.props

    try {
      if(isDeviceOnline){
        const obj = deviceConfig()
        const data = {
          subject: {
            studySite: { client: { id: clientID } },
            phoneNo: currentUser.userId,
            id: currentUser.id,
          },
          mobileInfo: {
            systemVersion: obj.systemVersion,
            os: obj.os,
            deviceId: deviceToken
          },
        }
        await api.post(`${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${currentUser.id}/logout`, data)

      }
      await removeCredentials()
      await clearStorageAndStoreSyncQueue()  
      await clearAndStopBackGroundJob(downloadImageTimerId)
      putItem('en_US', 'locale')
      setLocale('en_US')
      navigation.navigate('Login')
    } catch (error) {
      console.log(error)
    }
  }

  signoutFromDevice = async () => {
    try {
      const {
        navigation,
        screenProps: { setLocale },
        clientID,
        currentUser,
        clearStorageAndStoreSyncQueue,
        downloadImageTimerId,
        deviceToken,
        isDeviceOnline
      } = this.props

      if(isDeviceOnline){
        const obj = deviceConfig()
        const data = {
          subject: {
            studySite: { client: { id: clientID } },
            phoneNo: currentUser.phoneNo,
            id: currentUser.id,
          },
          mobileInfo: {
            systemVersion: obj.systemVersion,
            os: obj.os,
            deviceId: deviceToken
          },
        }
        await api.post(`${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${currentUser.id}/logout`, data)
      }
      this.hideModal()
      await removeCredentials()
      await clearStorageAndStoreSyncQueue()
      await clearAndStopBackGroundJob(downloadImageTimerId)
      setLocale('en_US')
      putItem('en_US', 'locale')
      navigation.navigate('Login')
    } catch (error) {
      console.log(error)
    }
  }
  signOut = () => {
    
    this.setState({
      isModalVisible: true,
    })
    
  }

  hideModal = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  
  render() {
    const {
      clientID,
      close,
      screenProps,
      closeDrawer,
      history,
      changeLoading,
      isDeviceOnline,
      navigation,
      screenProps: { t },
      retrieveHealthkitData,
      subject,
    } = this.props
    const { isModalVisible } = this.state
    return (
      <Container>
        {/* <NavigationEvents
            onWillFocus={() => {
              const currentDate = new Date();
              retrieveHealthkitData(subject.id, currentDate);
              }}
          /> */}
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          {/* <List style={{alignItems:'center',alignContent:'center',alignSelf:'center'}}> */}
          <List>
            <Authorize privilege="VIEW_HEALTH_DATA">
              <ListItem
                style={{ height: 60 }}
                icon
                onPress={() => {
                  setTimeout(() => {
                    navigation.navigate('Health')
                  }, 0)
                }}
              >
                <View style={styles.listItem}>
                  <View style={styles.iconContainer}>
                    <FontAwesome name="heart" size={24} />
                  </View>
                  <View style={styles.textContainer}>
                    <Text style={styles.text}>{t('HealthMenu')}</Text>
                  </View>
                </View>
              </ListItem>
            </Authorize>
            {/* User Data */}

            {/* Messages */}
            <Authorize privilege="VIEW_NOTIFICATIONS">
              {isDeviceOnline == true && (
                <ListItem
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('Messages')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <MaterialCommunityIcons name="bell" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('Notifications')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>

            {/* Change Language */}

            {/* <ListItem style={{ height: 60 }} icon icon onPress={() => { setTimeout(() => { navigation.navigate('ChangeLanguage') }, 0) }}>
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="translate" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('Actn_sheetChange_Language')}</Text>
                </View>
              </View>
            </ListItem> */}

            {/* Change Theme */}

            {/* <ListItem button style={{ height: 60 }} icon onPress={() => { setTimeout(() => { navigation.navigate('ChangeTheme') }, 0) }}>
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="color-lens" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('ChangeTheme')}</Text>
                </View>
              </View>
            </ListItem> */}
            <Authorize privilege="SETUP_APPLOCK">
              {Platform.OS !== 'web' && (
                <ListItem
                  button
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('PinChange')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <MaterialIcons name="lock" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('AppLock')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>

            {/* Change TimeZone */}
            <Authorize privilege="VIEW_TIMEZONE">
              {isDeviceOnline == true && (
                <ListItem
                  button
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('SubjectTimeZone')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <Feather name="globe" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('Timezone')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>

            {/* Contact */}

            <ListItem
              style={{ height: 60 }}
              icon
              onPress={() => {
                setTimeout(() => {
                  navigation.navigate('Contact')
                }, 0)
              }}
            >
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="perm-contact-calendar" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('Actn_sheetContact')}</Text>
                </View>
              </View>
            </ListItem>

            {/* FAQ */}
            <Authorize privilege="VIEW_FAQ">
              {isDeviceOnline == true && (
                <ListItem
                  style={{ height: 60 }}
                  icon
                  onPress={() => {
                    setTimeout(() => {
                      navigation.navigate('FAQ')
                    }, 0)
                  }}
                >
                  <View style={styles.listItem}>
                    <View style={styles.iconContainer}>
                      <FontAwesome name="question" size={24} />
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>{t('Actn_sheetFAQ')}</Text>
                    </View>
                  </View>
                </ListItem>
              )}
            </Authorize>
            {Platform.OS !== 'web' && (
              <ListItem
                style={{ height: 60 }}
                icon
                onPress={() => {
                  setTimeout(() => {
                    navigation.navigate('VersionInfo')
                  }, 0)
                }}
              >
                <View style={styles.listItem}>
                  <View style={styles.iconContainer}>
                    <FontAwesome name="info" size={24} />
                  </View>
                  <View style={styles.textContainer}>
                    <Text style={styles.text}>{t('VersionInfo')}</Text>
                  </View>
                </View>
              </ListItem>
            )}
            {/* Logout */}

            <ListItem
              style={{ height: 60 }}
              icon
              onPress={() => {
                setTimeout(() => {
                  navigation.navigate('SubjectDetails')
                }, 0)
              }}
            >
              <View style={styles.listItem}>
                <View style={styles.iconContainer}>
                  <MaterialIcons name="person" size={24} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{t('myProfile')}</Text>
                </View>
              </View>
            </ListItem>

              <ListItem
                button
                style={{ height: 60 }}
                icon
                onPress={() => {
                  setTimeout(() => {
                    this.signOut()
                  }, 0)
                }}
              >
                <View style={styles.listItem}>
                  <View style={styles.iconContainer}>
                    <MaterialCommunityIcons name="logout" size={24} />
                  </View>
                  <View style={styles.textContainer}>
                    <Text style={styles.text}>{t('Logout')}</Text>
                  </View>
                </View>
              </ListItem>
          </List>
          {isModalVisible && (
            <CustomModal
              title={''}
              onCancel={this.hideModal}
              onConfirm={Platform.OS === 'web' ? this.logoutAction : this.signoutFromDevice}
              message={t('LogoutMessage')}
              confirmText={t('YES')}
              cancelText={t('NO')}
              showCancelButton={true}
              confirmButtonColor={buttonBackgroundColor}
            />
          )}
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  subject: getSubject(state),
  clientID: _.get(getUserStudySite(state)?.client, 'id', null),
  isDeviceOnline: state.appStatus.isDeviceOnline,
  currentUser: getUser(state),
  downloadImageTimerId: state?.appStatus?.timers?.downloadImageTimerId,
  deviceToken: state.appStatus?.deviceToken
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveHealthkitData,
      updateDeviceToken,
      clearStorageAndStoreSyncQueue,
    },
    dispatch
  )


Sidebar.propTypes = {
  deviceToken: PropTypes.string.isRequired,
  isDeviceOnline: PropTypes.bool.isRequired,
  screenProps: PropTypes.shape({
    t: PropTypes.func,
    setLocale: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
