import api from '../api'
import { syncOfflineNotifications } from '../notifications/syncNotifications'
import { storage } from '../storage'
const checkIfCurrentScreenCanSyncData = (currentScreen) => {
  if (currentScreen != 'LOGIN' && currentScreen != 'PIN' && currentScreen != 'FORM') {
    return true
  }
  return false
}

export const checkForDataSync = async (
  appStatus,
  storeSyncStatus,
  updateSyncCompleteStatus,
) => {
  if (appStatus.isDeviceOnline && appStatus.OfflineFormsToSync > 0) {
    const check = checkIfCurrentScreenCanSyncData(appStatus.currentScreen)
    if (check == true) {
      const encryptedForms = await getOfflineForms()
      syncOfflineForms(
        encryptedForms,
        storeSyncStatus,
        updateSyncCompleteStatus,
      )
      syncOfflineNotifications()
    }
  }
}

export const syncOfflineForms = async (
  encryptedForms,
  storeSyncStatus,
  updateSyncCompleteStatus,
) => {
  try {
    api.defaults.headers.common['Accept-Language'] = 'en_US'
    api.defaults.headers.common['Client-Type'] = 'mobile'
    if (encryptedForms.length != 0 && encryptedForms != '[]') {
      storeSyncStatus(true)
      // await api.post(`crfData/data/sync`, JSON.parse(encryptedForms))
      updateSyncCompleteStatus(true)
      storage.delete('encryptedForms')
      setTimeout(() => {
        updateSyncCompleteStatus(false)
        storeSyncStatus(false)
      }, 1000)
    }
  } catch (error) {
    console.log(`ERROR : ${JSON.stringify(error)}`)
    storage.delete('encryptedForms')
    setTimeout(() => {
      updateSyncCompleteStatus(false)
      storeSyncStatus(false)
    }, 1000)
  }
}

export const getOfflineForms = async () => {
  // return [];
  try {
    const encryptedForms = storage.getString('encryptedForms')
    return encryptedForms || '[]'
  } catch (error) {}
}

export const storeOfflineForms = async (encryptedFormsList, newEncryptedForm) => {
  let encryptedForms = []
  if (encryptedFormsList.length != 0) {
    encryptedForms = JSON.parse(encryptedFormsList)
  }
  encryptedForms.push(newEncryptedForm)
  const encryptedFormsString = JSON.stringify(encryptedForms)
  try {
    storage.set('encryptedForms', encryptedFormsString)
  } catch (error) {
    console.log(error)
  }
}
