import React from "react"
import { StyleSheet, View } from "react-native"
import { Slider } from '@react-native-assets/slider'
import PropTypes from 'prop-types'

const VerticalScale = (props) => {
    const {  trackComponent, customThumb} = props
    console.log("vertical web props", props)
    return (
        <View style={styles.container}>
            <Slider 
                {...props}
                CustomMark={(obj) => trackComponent({index: obj.value})}
                CustomThumb={customThumb}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
VerticalScale.defaultProps = {
    trackComponent: ()=> null,
    customThumb: () => null,
  }
  VerticalScale.propTypes = {
    trackComponent: PropTypes.func,
    customThumb: PropTypes.func,
  }
export default VerticalScale