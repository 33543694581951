import initialState from '../store/initialState'
import {
  STORE_APP_STATUS,
  UPDATE_SYNC_QUEUE_TIMER,
  UPDATE_APP_TYPE,
  UPDATE_SPACE_AVAILABLE,
  UPDATE_DOWNLOADED_IMAGE_TIMER,
  UPDATE_SPACE_ACKNOWLEDGEMENT,
  UPDATE_ON_MOBILE_STATUS,
  LOGIN_RETRIEVE_FAILURE,
  LOGIN_RETRIEVE_REQUEST,
  UPDATE_DEVICE_TOKEN,
  UPDATE_PIN_VALIDATED
} from '../actions/storeAppStatus'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'

const storeAppStatus = (state = initialState.appStatus, action) => {
  switch (action.type) {
    case STORE_APP_STATUS:
      return { ...state, isDeviceOnline: action.isDeviceOnline }
    case CLEAR_USER_STORAGE:
      return {
        isDeviceOnline: true,
        OfflineFormsToSync: 0,
        currentScreen: 'FALSE',
        isSyncing: false,
        trainingForms: [],
        draftData: [],
        storage: {
          isStorageAvailable: true,
          isSpaceAlertAcknowledged: false,
        },
        timers: {
          downloadImageTimerId: null,
        }
      }
    case UPDATE_SYNC_QUEUE_TIMER: {
      return {
        ...state,
        syncQueueTimerId: action?.timerId
      }
    }
    
    case UPDATE_APP_TYPE:
      return { ...state, appType: action.appType }
    case UPDATE_SPACE_AVAILABLE:
        return {
          ...state,
          storage : {
            ...state.storage,
            isStorageAvailable: action.data,
          }  
        }
    case UPDATE_DOWNLOADED_IMAGE_TIMER: {
        return {
            ...state,
            timers: {
              ...state.timers,
              downloadImageTimerId: action?.timerId
            }     
          }
      }
    case UPDATE_SPACE_ACKNOWLEDGEMENT: {
      return {
      ...state,
      storage : {
        ...state.storage,
        isSpaceAlertAcknowledged: action?.isAcknowledged,
      } 
    }
  }
  case UPDATE_ON_MOBILE_STATUS:
      return { ...state, onMobile: action.onMobile }
  case LOGIN_RETRIEVE_REQUEST:
      return { ...state,loading: true }
  case LOGIN_RETRIEVE_FAILURE:
      return { ...state,loading: false }
  case UPDATE_DEVICE_TOKEN: {
    return { ...state,deviceToken: action.token }
  }
  case UPDATE_PIN_VALIDATED: {
    return { ...state,isPinValidated: action.isPinValidated }
  }
  default:
      return state
  }
  
}

export default storeAppStatus
