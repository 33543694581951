import _ from 'lodash'
import { createSelector } from 'reselect'
import { getUserId } from './user'
import { getSelectedSubjectVisitId, getSessionPool, getselectedFormId } from './session'
import constants from '../constants/constants'
import { getAppType } from './storeAppStatus'
import fieldTypes from '../constants/fieldTypes'


export const getstudyMetaDataPool = (state) => state.studyMetaData


//To get the user's studyMetaData from studyMetaDataPool
export const getUserStudyMetaData = createSelector([getstudyMetaDataPool, getUserId], (studyMetadataPool, userId) => {
    return _.get(studyMetadataPool, userId, {})
})

//To get the user's study
export const getUserStudy = createSelector([getUserStudyMetaData], (studyMetaData) => {
  return _.get(studyMetaData, 'study', {})
})

//To get the user's study loader
export const getStudyLoader = createSelector([getUserStudy], (study) => {
  return _.get(study, 'loading', false)
})

//To get the user's study dataFailed status
export const getStudyFailedStatus = createSelector([getUserStudy], (study) => {
  return _.get(study, 'dataFailed', false)
})

export const getSubjectVisits = createSelector([getUserStudyMetaData], (studyMetadata) => {
  return _.get(studyMetadata, 'subjectVisits', [])
})

export const getSubjectVisitForms = createSelector([getSubjectVisits], (subjectVisits) => {
  let allSubjectVisitForms = []
  _.forEach(_.map(subjectVisits, (sv) => sv?.subjectVisitForms), (svf) => {
      allSubjectVisitForms = allSubjectVisitForms.concat(svf)
    }
  )
 return _.orderBy(allSubjectVisitForms, 'scheduleStartTime')
})

export const getQuestionnaires = createSelector([getUserStudyMetaData], (studyMetadata) => {
  return _.get(studyMetadata, 'questionnaires', [])
})

export const getSelectedSubjectVisit = createSelector([getSubjectVisits, getSelectedSubjectVisitId], (subjectVisits, subjectVisitId) => {
  return _.filter(subjectVisits, (sv) => sv.id === subjectVisitId)
})

export const getAllSubjectVisitFormIds = createSelector([getSubjectVisitForms, getAppType], (subjectVisitForms, appType) => {
  return getFormIdsBasedOnAppType(subjectVisitForms, appType)
})

const getFormIdsBasedOnAppType = (subjectVisitForms, appType) => {
  let filteredSvfs = []
  if (_.isEqual(constants.AppType.SITESTAFF, appType)) {
    filteredSvfs = _.filter(subjectVisitForms, (svf) =>
      _.isEqual(svf?.form?.formType, constants.FormTypes.RATER_ASSESSMENT)
    )
  } else {
    filteredSvfs = _.filter(subjectVisitForms, (svf) =>
      _.isEqual(svf?.form?.formType, constants.FormTypes.SUBJECT_DIARY)
    )
  }
  return _.map(filteredSvfs, (svf) => svf?.form?.id)
}

//To retrieve loader of visits meta data
export const getMetadataLoader = createSelector([getUserStudyMetaData], (studyMetaData) => {
  return _.get(studyMetaData, 'loading', false)
})


//To retrieve loader of questionnaries
export const getQuestionnairesLoader = createSelector([getQuestionnaires], (questionnaires) => {
  return _.get(questionnaires, 'loading', false)
})

//To retrieve loader of questionnaries
export const getForms = createSelector([getQuestionnaires], (questionnaires) => {
  return _.get(questionnaires, 'data', [])
})

export const getFieldGroupList = createSelector(
  [getForms, getselectedFormId],
  (forms, formId) => {
    const form = _.filter(forms, form => _.isEqual(form.id, formId))?.[0]
    return !_.isEmpty(form) ? form.fieldGroups : []
  }
)

export const getSelectedForm = createSelector(
  [getForms, getselectedFormId],
  (forms, formId) => {
    const form = _.filter(forms, form => _.isEqual(form.id, formId))?.[0]
    return !_.isEmpty(form) ? form : {}
  }
)

export const getUserClickableImages = createSelector(
  [getUserStudyMetaData],
  (studyMetadata) => {
    return _.get(studyMetadata, 'images', {})
  }
)

export const getFormPreviewClickableImages = createSelector([getstudyMetaDataPool], (studyMetadataPool) => {
    return _.get(studyMetadataPool[constants.AppType.PREVIEW], 'images', {})
})

export const getFormPreviewFieldGroupList =  createSelector([getstudyMetaDataPool, getSessionPool], (studyMetadataPool, sessionPool) => {
  const selectedFormId = _.get(sessionPool[constants.AppType.PREVIEW], 'selectedFormId', null)
  const forms = _.get(studyMetadataPool[constants.AppType.PREVIEW]?.questionnaires, 'data', [])
  const form = _.filter(forms, form => _.isEqual(form.id, selectedFormId))?.[0]
  return !_.isEmpty(form) ? form.fieldGroups : []
})

export const getFormPreviewForm =  createSelector([getstudyMetaDataPool, getSessionPool], (studyMetadataPool, sessionPool) => {
  const forms = _.get(studyMetadataPool[constants.AppType.PREVIEW]?.questionnaires, 'data', [])
  return !_.isEmpty(forms) ? forms[0] : []
})

export const getFieldGroupListFormPrint =  createSelector([getstudyMetaDataPool, getSessionPool], (studyMetadataPool, sessionPool) => {
  const selectedFormId = _.get(sessionPool[constants.AppType.PRINT], 'selectedFormId', null)
  const forms = _.get(studyMetadataPool[constants.AppType.PRINT]?.questionnaires, 'data', [])
  const form = _.filter(forms, form => _.isEqual(form.id, selectedFormId))?.[0]
  return !_.isEmpty(form) ? form.fieldGroups : []
})


export const getFieldsFromFieldGroupList = createSelector([getFieldGroupList], (fgList) => {
  return _.flatten(_.map(fgList, (fg) => fg.fields)) || []
})

export const getFieldsFromFieldGroupListFormPreview = createSelector([getFormPreviewFieldGroupList], (fgList) => {
  return _.flatten(_.map(fgList, (fg) => fg.fields)) || []
})

export const getFieldsFromFieldGroupListFormPrint = createSelector([getFieldGroupListFormPrint], (fgList) => {
  return _.flatten(_.map(fgList, (fg) => fg.fields)) || []
})

export const getFormPrintForm =  createSelector([getstudyMetaDataPool, getSessionPool], (studyMetadataPool, sessionPool) => {
  const forms = _.get(studyMetadataPool[constants.AppType.PRINT]?.questionnaires, 'data', [])
  return !_.isEmpty(forms) ? forms[0] : []
})

export const getUserStudySite =  createSelector([getUserStudyMetaData], (studyMetadata) => {
  return _.get(studyMetadata, 'studySite', {})
})

export const getStudyData = createSelector([getUserStudy], (studyDetails) => {
  return _.get(studyDetails, 'data', {})
})


export const getCrfVersion = createSelector([getUserStudy], (studyDetails) => {
  return _.get(studyDetails, 'crfVersion', {})
})


export const isSvfClickableImagesDownloaded = createSelector([getFieldsFromFieldGroupList, getUserClickableImages], (fields, images) => {
  const clkImageFields = _.filter(fields, field => field.fieldType == fieldTypes.CLICKIMAGE)
  if(_.isEmpty(clkImageFields)){
    return true
  }else{
    const subjectImageMetadata = !_.isEmpty(images) ? images : {}
    if(_.isEmpty(subjectImageMetadata)){
      return false;
    }
    let notDownloadedImages = _.filter(clkImageFields, image => _.isEmpty(subjectImageMetadata[image.dictionary?.imageCode]?.downloadedUrl)) 
    return _.isEmpty(notDownloadedImages)
  }
})


//To retrieve existing questionnarie ids
export const getQuestionnairesFormIds = createSelector([getForms, getAppType], (forms, appType) => {
  let filteredForms = []
  if (_.isEqual(constants.AppType.SITESTAFF, appType)) {
    filteredForms = _.filter(forms, (form) =>
      _.isEqual(form?.formType, constants.FormTypes.RATER_ASSESSMENT)
    )
  } else {
    filteredForms = _.filter(forms, (form) =>
      _.isEqual(form?.formType, constants.FormTypes.SUBJECT_DIARY)
    )
  }
  return _.map(filteredForms, (form) => form?.id)
})
