import _ from 'lodash'
import {
  UPDATE_STUDY_META_DATA,
  STUDY_DETAILS_RETRIEVE_REQUEST,
  STUDY_DETAILS_RETRIEVE_SUCCESS,
  STUDY_DETAILS_RETRIEVE_FAILURE,
  UPDATE_IMAGE_CODES,
  UPDATE_DOWNLOADED_IMAGE_URL,
  GET_META_DATA_REQUEST,
  GET_META_DATA_FAILURE,
  GET_META_DATA_SUCCESS,
  NO_META_DATA_UPDATED,
  RETRIEVE_FORMS_WITH_FIELDS_REQUEST,
  RETRIEVE_FORMS_WITH_FIELDS_FAILURE,
  RETRIEVE_FORMS_WITH_FIELDS_SUCCESS,
  UPDATE_SVF_SUBMISSION_DATA,
  UPDATE_USER_STUDY_SITE
} from '../actions/studyMetaData'
import initialState from '../store/initialState'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'

export default (state = initialState.studyMetaData, action) => {
  switch (action.type) {
    case UPDATE_STUDY_META_DATA:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          study: {
            ...state?.[action.userId].study,
            loading: false,
            data: {
              ...state?.[action.userId]?.study?.data,
              ...action.data
            }
          }
        }
      }
    case STUDY_DETAILS_RETRIEVE_REQUEST:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          study: {
            ...state?.[action.userId].study,
            loading: true,
            dataFailed: false,
          } 
        }
      }
    case STUDY_DETAILS_RETRIEVE_SUCCESS:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          study: {
            ...state?.[action.userId]?.study,
            data: action.data,
            loading: false,
            dataFailed: false,
          } 
        }
      }
    case STUDY_DETAILS_RETRIEVE_FAILURE:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          study: {
            ...state?.[action.userId]?.study,
            loading: false,
            dataFailed: true,
          } 
        }
      }
    case CLEAR_USER_STORAGE:
      return {
        ...state,
        [action.userId]: {}
      }
        case UPDATE_IMAGE_CODES:
          return {
            ...state,
            [action.userId]:  {
              ...state[action.userId],
              images: action.data
            }
          }
        case UPDATE_DOWNLOADED_IMAGE_URL:
            return {
              ...state,
              [action.userId]:  {
                ...state?.[action?.userId],
                images: {
                  ...state?.[action?.userId]?.images,
                  [action.key]: {
                    ...state?.[action?.userId]?.images?.[action.key],
                    downloadedUrl: action.downloadedUrl
                  }
                }
              }
            }
        case GET_META_DATA_REQUEST:
          return {
            ...state,
            [action.userId]: {
              ...state?.[action.userId],
              loading: true,
            }
          }
       case GET_META_DATA_FAILURE:
          return {
            ...state,
            [action.userId]: {
              ...state?.[action.userId],
            loading: false,
            failed: true,
            }
          }
      case GET_META_DATA_SUCCESS:
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            ...action.metaData,
            loading: false,
            study: {
              ...state?.[action.userId]?.study,
              crfVersion: action.crfVersion,
            }
          }
        }
      case NO_META_DATA_UPDATED:
          return {
            ...state,
            [action.userId]: {
              ...state?.[action.userId],
              loading: false,
            }
          }
      case RETRIEVE_FORMS_WITH_FIELDS_REQUEST: {
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            questionnaires: {
              ...state?.[action.userId]?.questionnaires,
              loading: true
            }
          }
        }
      }
      case RETRIEVE_FORMS_WITH_FIELDS_FAILURE: {
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            questionnaires: {
              ...state?.[action.userId]?.questionnaires,
              loading: false
            }
          }
        }
      }
      case RETRIEVE_FORMS_WITH_FIELDS_SUCCESS: {
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            questionnaires: {
              ...state?.[action.userId]?.questionnaires,
              data: action.data,
              loading: false
            }
          }
        }
      }
      case UPDATE_SVF_SUBMISSION_DATA: {
        const userStudyMetadata = _.get(state, action.userId, {} )
        const updatedSvs = _.map(userStudyMetadata.subjectVisits, (sv) => {
          if (sv.id === action.subjectVisitId) {
            return {
              ...sv,
              subjectVisitForms: _.map(sv.subjectVisitForms, (svf) => {
                if (svf.id === action.svfId) {
                  return {
                    ...svf,
                    submissionStatus: action.status,
                    completedDateTime: action.completedDate,
                    status: action.svfStatus,
                  }
                }
                return svf
              }),
            }
          }
          return sv
        })
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            subjectVisits: updatedSvs
          }
        }
      }
      case UPDATE_USER_STUDY_SITE: 
        return {
          ...state,
          [action.userId]:{
            ...state?.[action.userId],
            studySite: action.studySite,
          }
        }
    default:
      return state
  }
}
