import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

const CustomThumb = ({id, disabled}) => {
    return (
        <View style={{
            borderRightWidth: 0,
            height: 0,
            width: 0,
            borderRadius: 0,
            backgroundColor: 'transparent',
            alignSelf: 'flex-start'
        }}
            accessible = {!disabled}
            testID= {`${id}-slider-thumb`}></View>
    )
}

CustomThumb.defaultProps = {
    disabled: false
}
CustomThumb.propTypes = {
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}
export default CustomThumb;