import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import constants from "../../../constants/constants";
import PropTypes from 'prop-types';
import { getEdgeLabelStyles, getScalePosition, getScaleWidth } from "../utils";
import _ from 'lodash';
import LabelTextDisplay from "../../fields/Visual Scale/LabelTextDisplay";
import { alignments } from "../alignments";
const { SCALE_POSITION, EDGE_LABEL_HEIGHT} = constants

const VerticalEdgeLabel = (props) => {
    const {value,labelsFlex, isTopLabel, scalePosition , options, transformedFontSize, startValueMarginBtm, disabled,id, isVAS=false} = props

    const isLeftSideLabelsPresent = !_.isEmpty(_.filter(options, op => op.labelPlacement === 'LEFT'))
    const isRightSideLabelsPresent = !_.isEmpty(_.filter(options, op => op.labelPlacement === 'RIGHT'))
    
    const isNoScaleSideLabelPresent = () => {
      return _.isEqual(scalePosition, SCALE_POSITION.CENTRE_OF_THE_PAGE) || (_.isEqual(scalePosition, SCALE_POSITION.LEFT_SIDE_OF_THE_PAGE) && !isLeftSideLabelsPresent) || (_.isEqual(scalePosition, SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE) && !isRightSideLabelsPresent)
    }

    const getMarginLeft = () => {
      if(isVAS){
        if(Platform.OS !== 'web'){
          switch (true) {
            case scalePosition === SCALE_POSITION.CENTRE_OF_THE_PAGE: return 10
            case scalePosition === SCALE_POSITION.LEFT_SIDE_OF_THE_PAGE: return 30
            case scalePosition === SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE: return -10
          }
        }else{
          switch (true) {
            case scalePosition === SCALE_POSITION.CENTRE_OF_THE_PAGE: return -10
            case scalePosition === SCALE_POSITION.LEFT_SIDE_OF_THE_PAGE: return -5
            case scalePosition === SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE: return -25
          }
        }
      }
      return 0
    }

    const textStyles = getEdgeLabelStyles(scalePosition, labelsFlex)
    let labelStyles = {...styles.labelStyles, width : getScaleWidth()/2, ...textStyles, marginLeft: getMarginLeft()}
    
    const getMarginBottom = () => {
      if(isTopLabel){
        return isVAS ? 10 : 5
      }else{
        return 0
      }
    }

    const getMarginTop = () => {
      if(!isTopLabel){
        return (isVAS ? 10 : (5-startValueMarginBtm))
      }else{
        return 0
      }
    }
    if(isNoScaleSideLabelPresent()){
      return (
        <View style={[styles.edgeLabelContainer, {marginBottom: getMarginBottom(), justifyContent: getScalePosition(scalePosition), paddingHorizontal: 2, marginTop: getMarginTop()}]} 
        testID={`numeric-rating-scale-sliderEdgeLabels-${id}-${isTopLabel?'top':'bottom'}-view`}
        accessible={!disabled}
        >
           <LabelTextDisplay 
              text={value}
              fontsize={transformedFontSize}
              containerWidth={getScaleWidth()/2}
              containerHeight={EDGE_LABEL_HEIGHT}
              labelStyles={labelStyles}
              testID={`numeric-rating-scale-sliderEdgeLabels-${id}-${isTopLabel?'top':'bottom'}-label`}
              accessible={!disabled} 
              containerStyle={{ width: getScaleWidth()/2}}    
              isFit={true}     
              orientation={alignments.VERTICAL}     
            />    
        </View>
      )
    }else{
      return (
        <View style={[styles.edgeLabelContainer,{marginBottom: getMarginBottom(), marginTop: getMarginTop()}]}
        testID={`numeric-rating-scale-sliderEdgeLabels-${id}-${isTopLabel?'top':'bottom'}-view`}
        accessible={!disabled}
        >
          {_.isEqual(scalePosition, SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE) && isRightSideLabelsPresent && (<View style={{flex: 0.5}}/>)}
          <View style={{flex: 0.5, alignItems: 'center'}}>
          <LabelTextDisplay 
              text={value}
              fontsize={transformedFontSize}
              containerWidth={getScaleWidth()/2}
              containerHeight={EDGE_LABEL_HEIGHT}
              labelStyles={labelStyles}
              testID={`numeric-rating-scale-sliderEdgeLabels-${id}-${isTopLabel?'top':'bottom'}-label`}
              accessible={!disabled} 
              containerStyle={{ width: getScaleWidth()/2}}
              isFit={true}  
              orientation={alignments.VERTICAL}                              
             /> 
          </View>
         {_.isEqual(scalePosition, SCALE_POSITION.LEFT_SIDE_OF_THE_PAGE) && isLeftSideLabelsPresent && ( <View style={{flex: 0.5}} />)}
        </View>
      )
    }
}


const styles = StyleSheet.create({
    edgeLabelContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    labelStyles: {textAlign: 'center'}
});

VerticalEdgeLabel.defaultProps = {
  value: null,
  labelsFlex: {},
  isTopLabel: false,
  scalePosition: SCALE_POSITION.CENTRE_OF_THE_PAGE,
  startValueMarginBtm: null,
  options: [],
  transformedFontSize: 10,
  disabled:false,
  id:null,
  isVAS: false,
}

VerticalEdgeLabel.propTypes = {
  value: PropTypes.string,
  labelsFlex: PropTypes.instanceOf(Object),
  isTopLabel: PropTypes.bool,
  scalePosition: PropTypes.string,
  startValueMarginBtm: PropTypes.number,
  options: PropTypes.arrayOf(Object),
  transformedFontSize: PropTypes.number,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isVAS: PropTypes.bool
}
  
export default VerticalEdgeLabel