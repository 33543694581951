import { getSiteIdFromSession } from "../utils/util"
import { getSessionPool } from "./session"
import { createSelector } from 'reselect'

export const getSites = (state) => state.site 



export const getSiteIdFromStorage = createSelector([getSessionPool], (sessionPool) => {
    const currentSiteId = _.get(sessionPool, 'currentSiteId', null)
    return getSiteIdFromSession(currentSiteId)
})

export const getSiteFromSessionStorage = createSelector([getSites, getSiteIdFromStorage], (sites, siteId) => {
     return _.get(sites, siteId, {})
})