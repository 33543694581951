import React, { Component } from 'react'
import { View, Platform } from 'react-native'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import { getTimezoneCode } from '../../utils/util'
import PropTypes from 'prop-types'
import constants from '../../constants/constants'
import Datepicker from '../webDatePicker/Datepicker'

const {dateFormats} = constants

export class DateTime12 extends Component {
  changeDate = (date) => {
    const {
      form,
      field: { id },
      subjectTimezone,
    } = this.props
    const { setFieldsValue } = form
    setFieldsValue({
      [id]: Platform.OS !== 'web' ? date : date + ' ' + getTimezoneCode(subjectTimezone),
    })
  }

  render() {
    const {
      field: { id, isRequired, uiSchema: fdUISchema },
      t,
      subjectTimezone,
      disabled,
      fieldAnswer,locale
    } = this.props
    const crfData = { ...fieldAnswer }

    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
      >
        {this.props.form.getFieldDecorator(`${id}`, {
          initialValue: crfData && crfData.fieldValue ? crfData.fieldValue : null,
          valuePropName: Platform.OS === 'web' ? 'date' : 'value',
          rules: [
            {
              required: isRequired,
              message: t('DateValMsg'),
              whitespace: true,
            },
          ],
        })(
            <Datepicker
              showTimeInput={true}
              timeFormat="hh:mm a"
              placeHolder={t('SelectDate')}
              valueFormat={dateFormats.DATETIME12}
              shortTimezone={getTimezoneCode(subjectTimezone)}
              disabled={disabled}
              testID={`DateTime12-Field-${id}-view`}
              locale={locale}
              t={t}
              mode="datetime12"
              onChange={(date) => this.changeDate(date)}
              date={crfData?.fieldValue}
              timezone = {subjectTimezone}
            />
      )}
      </View>
    )
  }
}

DateTime12.defaultProps = {
  t: () => {},
  disabled: false,
  fieldAnswer: {},
  subjectTimezone: null,
}

DateTime12.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  t: PropTypes.func,
  disabled: PropTypes.bool,
  fieldAnswer: PropTypes.object,
  subjectTimezone: PropTypes.string,
  locale: PropTypes.string.isRequired
}
