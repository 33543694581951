import React from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import { FcCalendar } from 'react-icons/fc'
import moment from 'moment'
import { StyleSheet, Dimensions} from 'react-native'
import './customDatePickerWidth.css'
import './DatePicker.css'

function CustomInput({ onClick, date, isDiaryDatePicker }) {
  return (
    <button 
      type="button" 
      onClick={onClick} 
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick(e);
        }
      }}
      className="dateInput" 
      style={styles.customDateInput}
    >
      <div style={styles.iconWrapper}>
        <FcCalendar size="1.1em" />
      </div>
      <div style={styles.dateText}>{isDiaryDatePicker ? date : moment(date).format('DD-MMM-yyyy')}</div>
    </button>
  );
}

CustomInput.defaultProps = {
  onClick: () => {},
  date: null,
  isDiaryDatePicker: false
}

CustomInput.propTypes = {
  onClick: PropTypes.func,
  date: PropTypes.string,
  isDiaryDatePicker: PropTypes.bool
}

function CustomDateInput({
  onChange,
  date,
  showTimeInput,
  timeFormat,
  valueFormat,
  t,
  locale,
  isDiaryDatePicker,
  disabled }) {

  const { width: SCREEN_WIDTH } = Dimensions.get('window');
  let datePickerClass;

  if (SCREEN_WIDTH >= 1024) {
    datePickerClass = 'webdatepk15';
  } else if (SCREEN_WIDTH >= 750) {
    datePickerClass = 'webdatepk22';
  } else if (SCREEN_WIDTH > 350) {
    datePickerClass = 'webdatepk12';
  } else {
    datePickerClass = 'webdatepk10';
  }
  

  

  function renderMonth(props, month, year, selectedDate) {
    const monthName = moment.months(month)
    return <td {...props}>{t(monthName)}</td>
  }
  return (
    <div 
      className="customWdth"
      style={styles.wrapper}
    >
      <Datetime
        renderMonth={renderMonth}
        initialValue={isDiaryDatePicker ? date : moment(date)}
        dateFormat={valueFormat}
        timeFormat={showTimeInput ? timeFormat : false}
        className={datePickerClass}
        onChange={(selectedDate) => 
          onChange(isDiaryDatePicker? moment(selectedDate.toDate()).format(valueFormat) : selectedDate)
        }
        renderInput={(p,openCalendar) => (
          isDiaryDatePicker &&
          <CustomInput date={date} onClick={openCalendar} isDiaryDatePicker={isDiaryDatePicker}/>
        )}
        closeOnSelect={true}
        inputProps={{
          className: 'inputProps',
        }}
        initialViewMode="days"
        viewMode="days"
        locale={locale}
        portalId="root-calendar"
        input={isDiaryDatePicker}
        disabled={disabled}
      />
    </div>
  )
}


  const styles = StyleSheet.create({
    customDateInput: {
      width: 180,
      border: 'none',
    },
    iconWrapper: {
      display: 'inline-block',
      paddingRight: 10,
    },
    dateText: {
      display: 'inline-block',
    },
    wrapper: {
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  CustomDateInput.defaultProps = {
  onChange: () => {},
  date: '',
  showTimeInput: false,
  timeFormat: 'HH:mm',
  valueFormat: 'DD-MMM-YYYY',
  disabled: false,
  isDiaryDatePicker: false
}

CustomDateInput.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.string,
  showTimeInput: PropTypes.bool,
  timeFormat: PropTypes.string,
  valueFormat: PropTypes.string,
  disabled: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isDiaryDatePicker: PropTypes.bool
}

export default CustomDateInput
